import React from 'react';
import { useTranslation } from 'bb/i18n';
import { makeCtaTrackerName } from 'bb/tracker/hooks/useCtaTracker';
import { type ButtonVariant } from 'bb/ui';
import { SignupButton, type SignupButtonProps } from './SignupButton';
import { type SignupCtaProps } from './types';

interface CampaignButtonProps<Variant extends ButtonVariant>
    extends Omit<SignupCtaProps & SignupButtonProps<Variant>, 'route'> {
    campaignQuery?: { campaign: string };
}

export const CampaignButton = <Variant extends ButtonVariant>({
    name,
    variant,
    campaignQuery,
    children,
    ...restProps
}: CampaignButtonProps<Variant>) => {
    const { t } = useTranslation('cta');
    const trackerName = makeCtaTrackerName(name);

    return (
        <SignupButton
            name={trackerName}
            variant={variant}
            query={campaignQuery}
            data-testid="campaign-button"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        >
            {children || t('cta:campaign')}
        </SignupButton>
    );
};
