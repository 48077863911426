import { type BBApiTypes } from 'bb/api/types';
import { type HalLinks, type HalObject } from 'bb/common/types';
import { type PageProps } from 'bb/page/types';
// eslint-disable-next-line import/no-cycle
import {
    type Badge,
    type BookLightResponse,
    type BookListResponse,
    type SingleSale
} from '../types';

export type BookFormat = 'audioBook' | 'eBook' | 'audiodrama';

export type Rating = {
    numberOfRatings: number;
    ratingValue: number;
};

export type Review = {
    bookid: number;
    bookrating: number | null;
    createdat: string;
    id: string;
    market: number;
    narrationrating: number | null;
    reviewername: string;
    reviewtext: string;
    title: string;
    upvotecount: number;
};

export enum ContributorRole {
    AUTHOR = 'bb-author',
    EDITOR = 'bb-editor',
    NARRATOR = 'bb-narrator',
    TRANSLATOR = 'bb-translator'
}

export type Contributor = {
    id: number;
    firstname: string;
    lastname?: string;
    displayname: string;
    role: string;
    description?: string;
    booksurl?: string;
};

export type EnhancedContributor = {
    metaTitle?: string;
    metaDescription?: string;
    self?: string;
    imageurl?: string;
    authoredbooksurl?: string;
    narratedbooksurl?: string;
    roles?: string[];
} & Partial<Contributor>;

export type BookSeries = {
    count: number;
    id: number;
    name: string;
    nextbookid?: number | null;
    partindex?: number;
    partnumber?: number;
    prevbookid?: number | null;
    url: string;
};
export type CopyrightOwner = { year: number; name: string };

export type Edition = {
    availablefrom?: string;
    bookBeatPublishDate?: string;
    bookBeatUnpublishDate?: string | null;
    contributors?: Array<Contributor>;
    copyrightOwners?: Array<CopyrightOwner>;
    format: BookFormat;
    id: number;
    isbn: string;
    language?: string;
    previewenabled: boolean;
    published?: string;
    publisher?: string;
    singlesale?: SingleSale | null;
};

export type Genre = {
    booksurl: string;
    genreid: number;
    name: string;
    parentid: number | null;
};

export type Book = {
    Type?: number | null;
    audiobooklength?: number;
    author?: string;
    badges: Badge[];
    bookappviewurl?: string | null;
    contenttypetags?: Array<string>;
    cover?: string;
    ebookduration?: number | null;
    ebooklength?: number;
    editions: Array<Edition>;
    genres?: Array<Genre>;
    grade?: number;
    id: number;
    language: string;
    markets: Array<string>;
    narratingRating?: Rating;
    narrator?: string;
    nextcontenturl?: string;
    originaltitle?: string | null;
    published?: string;
    rating?: Rating;
    relatedreadingsurl?: string;
    series?: BookSeries;
    shareurl?: string;
    subtitle?: string | null;
    summary?: string;
    title: string;
    translator?: string | null;
    upcomingeditions: Array<Edition>;
};

export type BookPrice = BBApiTypes['ApiEditionPrice'];

export type BookLight = {
    _links: {
        book: string;
    };
    bookId: number;
    id?: number;
    bookid?: number;
    bookRating?: number;
    comment: string;
    narrationRating?: number;
    progress: number;
    rating: number;
    reviewText?: string;
};

export type BookLink = HalObject<never, 'book'> & {
    bookid: number;
    date: string;
};

export type ReviewsResponse = {
    count: number;
    _links: HalLinks;
    _embedded: {
        reviews: Review[];
    };
};

export type BookPageData = {
    book: Book;
    reviews: ReviewsResponse | null;
    singleSalesPrices: Record<string, BookPrice[]>;
    relatedReadings?: BookListResponse;
    booksByAuthor?: BookListResponse;
    booksInSeries?: BookLightResponse[];
};

export type BookPageProps = BookPageData & PageProps;
