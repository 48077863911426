import React from 'react';
import { useRouter } from 'bb/app/router/useRouter';
import { DeprecatedLinkButton } from 'bb/common/DeprecatedLinkButton';
import { useTranslation } from 'bb/i18n';
import { useCtaTracker } from 'bb/tracker/hooks/useCtaTracker';
import { type ButtonVariant } from 'bb/ui';
import { type SignupButtonProps } from './SignupButton';
import { type SignupCtaProps } from './types';

export type RedeemButtonProps<Variant extends ButtonVariant> = SignupCtaProps &
    SignupButtonProps<Variant> & {
        campaignQuery?: { campaign: string };
    };

export const RedeemButton = <Variant extends ButtonVariant>(
    props: RedeemButtonProps<Variant>
) => {
    const { routes } = useRouter();
    const {
        name,
        variant,
        campaignQuery,
        children,
        route = routes.campaigns
    } = props;

    const { t } = useTranslation('cta');
    const tracker = useCtaTracker();
    const trackerName = `${name}-redeem-campaign`;

    return (
        <DeprecatedLinkButton
            variant={variant}
            route={route}
            onClick={() => tracker.accountCta(trackerName)}
            query={campaignQuery}
            data-testid="redeem-button"
        >
            {children || t('cta:campaign')}
        </DeprecatedLinkButton>
    );
};
