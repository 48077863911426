import React from 'react';
import classNames from 'classnames';
import { FilterIcon, SortIcon } from 'bb/ui';
import { Button, type ButtonProps } from 'bb/ui/Button';
import css from './filterButton.module.scss';

export type FilterButtonProps = {
    icon: 'filter' | 'sorting';
    align: 'left' | 'right';
} & Omit<
    ButtonProps,
    'type' | 'disableAnimation' | 'startAdornment' | 'endAdornment'
>;

export const FilterButton = React.forwardRef<
    HTMLButtonElement,
    FilterButtonProps
>((props, ref) => {
    const { children, className, align, icon, ...restProps } = props;

    return (
        <Button
            {...restProps}
            ref={ref}
            className={classNames(css.root, css[`align-${align}`], className)}
            type="button"
            disableAnimation
            startAdornment={
                icon === 'filter' ? (
                    <FilterIcon size="small" />
                ) : (
                    <SortIcon size="small" />
                )
            }
        >
            {children}
        </Button>
    );
});
