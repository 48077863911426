import React, { type ReactNode } from 'react';
import { observer } from 'bb/app/stores';
import { useSubscriptionStore } from 'bb/subscription';
import { isDef } from 'bb/utils/assert';
import { TrialDaysText } from '../TrialDaysText';
import { SignupButton } from './SignupButton';
import { type SignupCtaProps } from './types';

export type TrialDaysButtonProps = {
    fallbackButton: ReactNode;
} & SignupCtaProps;

export const TrialDaysButton = observer(
    ({
        name,
        variant,
        suppressHydrationWarning,
        fallbackButton,
        ...restProps
    }: TrialDaysButtonProps) => {
        const subscriptionStore = useSubscriptionStore();

        if (!isDef(subscriptionStore.subscriptionProducts?.trialDays)) {
            return fallbackButton;
        }

        return (
            <SignupButton
                suppressHydrationWarning={suppressHydrationWarning}
                name={name}
                variant={variant}
                data-testid="trial-days-button"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...restProps}
            >
                <TrialDaysText
                    data-optimizely="trial-days-button-text"
                    trialDays={subscriptionStore.subscriptionProducts.trialDays}
                />
            </SignupButton>
        );
    }
);
