/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { type MagicLinkData } from 'bb/account/types';
import { DeprecatedButton, type ButtonProps, type ButtonVariant } from 'bb/ui';
import { isProductionEnvironment } from 'bb/utils/environment';

export const TEST_NAMESPACE = 'bookbeat-test';
export const LIVE_NAMESPACE = 'bookbeat';

export type OpenAppSchemeNamespace =
    | typeof LIVE_NAMESPACE
    | typeof TEST_NAMESPACE;
export type OpenAppScheme = `${OpenAppSchemeNamespace}://`;
export type OpenAppHref = `${OpenAppScheme}${string}`;

export const makeOpenAppScheme = (): OpenAppScheme =>
    `${isProductionEnvironment() ? LIVE_NAMESPACE : TEST_NAMESPACE}://`;

export const makeMobileAppBaseHref = (): OpenAppHref =>
    `${makeOpenAppScheme()}${
        isProductionEnvironment() ? 'www' : 'test'
    }.bookbeat.com`;

export const makeMobileAppHref = (
    data: MagicLinkData,
    source: string
): OpenAppHref =>
    `${makeMobileAppBaseHref()}/magiclink?token=${data.token}&source=${source}`;

export type OpenAppButtonProps<
    TButtonVariant extends ButtonVariant = 'primary'
> = Omit<ButtonProps<TButtonVariant, 'a'>, 'href' | 'as'> & {
    href: OpenAppHref;
};

export const OpenAppButton = <T extends ButtonVariant = 'primary'>({
    onClick,
    children,
    href,
    ...restProps
}: OpenAppButtonProps<T>) => (
    <DeprecatedButton as="a" onClick={onClick} href={href} fluid {...restProps}>
        {children}
    </DeprecatedButton>
);
