import React, { type FC } from 'react';
import { Text, useDaysPeriod } from 'bb/i18n';

export const TrialDaysText: FC<{
    trialDays: number | null;
}> = ({ trialDays, ...restProps }) => {
    const { period } = useDaysPeriod(trialDays);

    return period ? (
        <Text
            t="cta:trial"
            values={{ period }}
            as="span"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        />
    ) : (
        <Text
            t="cta:fallback"
            as="span"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        />
    );
};
